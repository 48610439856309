import React from 'react';
import './Header.scss';
import Logo from './../../assets/logo.png';
import Background from './../../assets/background.jpg';
import buttonIcon from './../../assets/button_icon.svg';

interface State {

}

interface Props {



}

var headerStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
   // background:`linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)), url(${Background})`,

}

export default class Header extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    doRedirect(){
        window.location.href = "https://go.getenecta.de"
    }



    render(){

        return(
            <div onClick={this.doRedirect.bind(this)} className="header" style={headerStyle}>
                <div className="header_overlay">
               
               <div className="header_content">
               <img className="logo_img" src={Logo}></img>
               
              

               <p>
               ENECTA PRODUKTE JETZT ZUM SUPERSPARPREIS KAUFEN!
               </p>



               <div className="button_div">
                  <a href="https://go.getenecta.de"> Zum Shop <img src={buttonIcon}></img>  </a>
               </div>
               </div>

                

                </div>
            </div>
        )
    }
}