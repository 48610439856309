import React from 'react';
import vegan from './../../assets/vegan.png';
import nongmo from './../../assets/non-gmo.png';
import labor from './../../assets/laborgetestet.png';
import naturbelassen from './../../assets/natur.png';



interface State {

}

interface Props {

}

export default class Kunden extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="kunden">
            
             <div className="kunden_wrapper">
                <div className="kunden_img_wrapper">
                <img src={naturbelassen}></img>
               
                </div>
                
                <div className="kunden_img_wrapper">
                <img src={labor}></img>
                </div>

                <div className="kunden_img_wrapper">
                <img src={vegan}></img>
                </div>
                <div className="kunden_img_wrapper">
                <img src={nongmo}></img>
               
                </div>

             </div>
            </div>
        )
    }
}