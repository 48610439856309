import React from 'react';

import Background from './../../assets/background.jpg';

interface State {

}

interface Props {

}


var footerStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover'
}

export default class Footer extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="footer" >
           
           <div className="footer_inner_wrapper">
                 
             <div className="footer_col_left">
                <p>Große Auswahl an Enecta Produkten</p>
                <p> bei Thankyoujane.de</p>
             </div>
            

            <div className="footer_col_right">
            <p>Thankyoujane GmbH</p>
            <p>Meichelbeckstr. 23</p>
            <p>81545 München</p>




            </div>
          
           </div>
            </div>
        )
    }
}