import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Benefits from './components/Benefits/Benefits';
import Kunden from './components/Kunden/Kunden';



function App() {
  return (
    <div className="App">
      <Header></Header>
   
      <Kunden></Kunden>
      <Footer></Footer>
    </div>
  );
}

export default App;
